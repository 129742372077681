<template>
  <Transition name="fade">
    <div
      v-show="isBackdrop && active"
      class="geoip-locale-switcher-backdrop"
    />
  </Transition>

  <Transition name="fade">
    <dialog
      v-if="active && content"
      ref="dialog"
      class="geoip-locale-switcher"
      :open="active"
    >
      <div class="geoip-locale-switcher__header">
        <span class="geoip-locale-switcher__header-wrap">
          <span class="geoip-locale-switcher__header-icon">
            <SvgIconTranslate />
          </span>

          <span>{{ content.header }}</span>
        </span>

        <ButtonBase
          class="geoip-locale-switcher__button geoip-locale-switcher__button--close"
          data-gtm="localeSwitchPopup__close"
          @click="close"
        >
          <SvgIconCross />
        </ButtonBase>
      </div>

      <div class="geoip-locale-switcher__body">
        <T3HtmlParser
          class="geoip-locale-switcher__content"
          :content="content.bodytext"
        />

        <div class="geoip-locale-switcher__cta">
          <ButtonBase
            class="geoip-locale-switcher__button geoip-locale-switcher__button--secondary"
            :button="{ link: content.suggestedLocale.link }"
            data-gtm="localeSwitchPopup__change"
          >
            <T3HtmlParser :content="content.suggestedLocale.text" />

            <NavFlagIcon
              :iso-code="switchToLocaleCode"
              class="geoip-locale-switcher__flag"
            />
          </ButtonBase>

          <ButtonBase
            class="geoip-locale-switcher__button geoip-locale-switcher__button--primary"
            data-gtm="localeSwitchPopup__stay"
            @click="close"
          >
            <T3HtmlParser :content="content.currentLocale.text" />
          </ButtonBase>
        </div>
      </div>
    </dialog>
  </Transition>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'

import SvgIconCross from '~ui/assets/icons/icon-cross.svg'
import SvgIconTranslate from '~ui/assets/icons/icon-translate.svg'
import { ButtonBase, NavFlagIcon } from '~ui/components'
import type { BaseGeoipLocaleSwitcherProps } from '~ui/types'

const props = defineProps<BaseGeoipLocaleSwitcherProps>()

const dialog = ref<HTMLDialogElement | null>()

const isBackdrop = computed(() => {
  return props?.backdrop || false
})

watch(
  () => props.active,
  (state) => {
    nextTick(() => state ? dialog.value?.show() : dialog.value?.close())
  }
)

const emit = defineEmits(['close'])
const close = () => {
  emit('close')
}
</script>

<style lang="scss">
.geoip-locale-switcher {
  $border-color: rgba(color(tarawera), 10%);

  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate3d(-50%, -50%, 0);
  z-index: z-index(header);
  width: 100%;
  max-width: 94%;
  font-weight: 500;
  font-size: rem(18px);
  color: color(tarawera);
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 10px 18px rgba(color(black), 40%);

  @include media-query(sm) {
    max-width: rem(720px);
  }

  &__header {
    padding: rem(20px);
    border: 1px solid $border-color;
    font-weight: 600;

    &-wrap {
      display: flex;
      align-items: center;
    }

    &-icon {
      color: color(blue-1000);
      margin-right: rem(16px);
    }
  }

  &__body {
    border: 1px solid $border-color;
    background-color: color(grey-150);
  }

  &__content,
  &__cta {
    padding: rem(20px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span {
      text-transform: uppercase;
    }
  }

  &__content span {
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: underline;
  }

  &__cta {
    display: grid;
    gap: rem(24px);
  }

  &__button {
    margin: 0;

    & > span {
      padding: rem(20px);
      height: auto;
      font-weight: 600;

      & > div > span {
        font-weight: 700;
      }
    }

    &--close {
      @include button-style(color(tarawera), color(white), 2.5, $border-color);
      border-width: 1px;
      aspect-ratio: 1;

      & > span {
        pointer-events: none;
        display: grid;
        place-items: center;
        padding: rem(10px);

        & > svg {
          height: 24px;
          width: 24px;

          & path {
            fill: color(tarawera);
          }
        }
      }
    }

    &--primary {
      @include button-style(color(tarawera), color(white), 2.5);
    }

    &--secondary {
      @include button-style(color(white), color(tarawera), 2.5);

      & > span {
        display: flex;
        justify-content: center;
        gap: rem(20px);
      }
    }
  }

  &__flag {
    display: none;

    @include media-query(sm) {
      display: flex;
    }
  }
}

.geoip-locale-switcher-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(header);
  width: 100vw;
  height: calc(100vh - var(--subdrop-offset, 0px));
  height: calc(100svh - var(--subdrop-offset, 0px));
  background-color: rgba(color(dark-blue-950), 35%);
  backdrop-filter: blur(rem(4px));
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.2s;
}
</style>
