<template>
  <GeoipLocaleSwitcher
    :active="displaySwitcher"
    :content="content"
    :switch-to-locale-code="switchToLocaleCode"
    backdrop
    @close="handleClose"
  />
</template>

<script setup lang="ts">
import type { T3InitialData } from '@t3headless/nuxt-typo3'
import { withoutTrailingSlash } from 'ufo'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { useNuxtApp, useRuntimeConfig } from '#app'
import { useCurrentLocale, useT3Api, useT3Options } from '#imports'

import GeoipLocaleSwitcher from '~ui/components/Base/GeoipLocaleSwitcher/GeoipLocaleSwitcher.vue'
import { getCookie } from '~ui/helpers/cookie'

import countryNameTranslation from './countryNameTranslation.json'
import languageMap from './languageMap.json'

type countryNameTranslationKey = keyof typeof countryNameTranslation
type languageMapKey = keyof typeof languageMap

const COOKIE_LOCALE_PREFERENCE = 'geoip_locale_preference'

const { callHook, hook } = useNuxtApp()
const { options } = useT3Options()
const { getInitialData, initialData, pageData } = useT3Api()

const config = useRuntimeConfig()
const forceGeoipLocaleSwitcher = !!config.public?.FORCE_GEOIP_LOCALE_SWITCHER
const hasLocalesAvailable = pageData.value?.i18n && pageData.value.i18n.length > 1

const currentLocale = useCurrentLocale()
const defaultLocale = ref(options.i18n.default)
const isLocaleCookieSet = ref(false)
const isCookieConsent = ref(false)

const geoipCountryCode = ref<languageMapKey | null>(null)
const geoipContent = ref<T3InitialData['languageSelectorPopup'] | null>(null)

const getLocaleObject = (countryCode: languageMapKey, strict?: boolean) => {
  const match = pageData.value?.i18n
    .map((locale) => ({ ...locale, countryCode: locale.flag?.replace('flags-', '')?.toUpperCase() as languageMapKey }))
    .find((locale) => locale.countryCode === countryCode || locale.twoLetterIsoCode === languageMap[countryCode])

  if (!match && !strict) {
    return initialData.value.i18n.find((locale) => locale.twoLetterIsoCode === defaultLocale.value)
  }

  return match
}

const getLocaleLink = (countryCode: languageMapKey) => {
  return getLocaleObject(countryCode)!.link
}

const getLocalCountryName = (countryCode: languageMapKey) => {
  if (!(Intl && 'DisplayNames' in Intl)) return countryCode

  const lang = languageMap[countryCode] ?? (countryCode || defaultLocale.value)
  const countryName = new Intl.DisplayNames(lang, { type: 'region' }).of(countryCode) as countryNameTranslationKey

  return countryNameTranslation[countryName] || countryName
}

const displaySwitcher = computed(() => {
  if (!geoipCountryCode.value || !geoipContent.value) return false

  const geoipLocaleObject = getLocaleObject(geoipCountryCode.value)

  if (geoipLocaleObject?.available === 0) return false
  return geoipLocaleObject?.twoLetterIsoCode !== currentLocale.value
})

const isLocaleAvailable = computed(() => {
  return !!getLocaleObject(geoipCountryCode.value!, true)
})

const content = computed(() => {
  if (!displaySwitcher.value) return null

  const infoText = geoipContent.value!.infoText.replace(
    '{{current}}',
    `<span>${getLocalCountryName(geoipCountryCode.value!)}</span>`
  )
  const availabilityText = isLocaleAvailable.value
    ? geoipContent.value?.availableText
    : geoipContent.value?.unavailableText

  return {
    header: geoipContent.value!.header,
    bodytext: `${infoText} ${availabilityText}`,
    currentLocale: { text: geoipContent.value!.currentLocaleText },
    suggestedLocale: {
      text: geoipContent.value!.suggestedLocaleText,
      link: withoutTrailingSlash(getLocaleLink(geoipCountryCode.value!))
    }
  }
})

const switchToLocaleCode = computed(() => {
  return geoipCountryCode.value?.toLowerCase() || ''
})

const fetchGeoipCountryCode = async () => {
  const data = await $fetch<string>('/geoip', {
    baseURL: options.api.baseUrl
  })

  if (data && data in languageMap) {
    geoipCountryCode.value = data as languageMapKey
  }
}

const fetchGeoipContent = async () => {
  const isoCode = initialData.value.i18n
    .map((locale) => ({ ...locale, countryCode: locale.flag?.replace('flags-', '')?.toUpperCase() as languageMapKey }))
    .find((locale) => locale.countryCode === geoipCountryCode.value || locale.twoLetterIsoCode === languageMap[geoipCountryCode.value!])
    ?.twoLetterIsoCode || options.i18n.default

  const path = isoCode === options.i18n.default ? '' : '/' + isoCode
  const { languageSelectorPopup } = await getInitialData(path)

  geoipContent.value = languageSelectorPopup ?? null
}

const checkCookibotConsent = () => {
  // @ts-ignore
  if (typeof Cookiebot !== 'undefined' && Cookiebot?.consent?.preferences) {
    isCookieConsent.value = true
  }
}

const handleClose = (locale: string) => {
  geoipCountryCode.value = null

  callHook('gtm', {
    event: 'language_change',
    language: locale ?? currentLocale.value
  })
}

onMounted(() => {
  isLocaleCookieSet.value = !!getCookie(COOKIE_LOCALE_PREFERENCE)

  // we want to show modal only if user allowed preferences cookies,
  // because user's choice in modal is stored in cookies
  window.addEventListener('CookiebotOnConsentReady', checkCookibotConsent)
  window.addEventListener('CookiebotOnLoad', checkCookibotConsent)

  hook('t3:i18n', (locale) => handleClose(locale))
})

onBeforeUnmount(() => {
  window.removeEventListener('CookiebotOnConsentReady', checkCookibotConsent)
  window.removeEventListener('CookiebotOnLoad', checkCookibotConsent)
})

watch(isCookieConsent, (consent) => {
  if ((forceGeoipLocaleSwitcher || consent) && !isLocaleCookieSet.value && hasLocalesAvailable) {
    fetchGeoipCountryCode().then(() => {
      if (geoipCountryCode.value) fetchGeoipContent()
    })
  }
}, { immediate: true })

watch(displaySwitcher, (value) => {
  document.body.classList.toggle('geoip-dialog-active', value)
})
</script>
